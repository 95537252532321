import React from 'react'
import Mission from '../../Section/Mission/Mission'

function Contact() {
  return (
    <div className="contactwrper">
        <Mission />
    </div>
  )
}

export default Contact